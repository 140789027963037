@use 'abstracts' as *;

.modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  background: $color__white;
  box-shadow: 0 6px 8px 0 rgba(44, 8, 8, 0.5);
  transform: translateX(0);
  visibility: visible;
  transition: visibility 0.4s linear, transform 0.4s ease-in-out;

  a {
    color: $color__blue-400;
    text-decoration: none;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: $color__blue-600;
    }
  }

  &--hidden {
    transform: translateX(100%);
    visibility: hidden;
  }

  &__header,
  &__footer,
  &__content {
    padding-right: rem(20px);
    padding-left: rem(20px);
    @include breakpoint('medium') {
      padding-right: rem(32px);
      padding-left: rem(32px);
    }
  }

  &__header,
  &__footer {
    display: flex;
    flex: 1 1 auto;
    min-height: rem(80px);
  }

  &__footer {
    padding-top: rem(24px);
    padding-bottom: rem(32px);
  }

  &__header {
    position: relative;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: rem(32px);
    padding-bottom: rem(24px);
    background-color: $color__highlight-cell;

    .modal--filter & {
      border-bottom: 0;
    }

    .btn {
      display: inline-flex;
      /* prettier-ignore */
      margin-bottom: rem(-8px); // Offset bottom padding so icon is level with text
      margin-left: rem(24px);
      @include font-size(20px);
    }
  }

  &__title {
    margin: 0;
    color: $color__gray-300;
    @include font-size(24px);
    font-weight: 500;
  }

  &__footer {
    flex-wrap: wrap;
    justify-content: flex-end;
    border-top: 1px solid rgba($color__gray-400, 0.24);

    a {
      @extend %body__text-xs;

      .icon {
        margin-left: rem(4px);
        font-size: 0.8em;
      }
    }

    .modal__reset {
      margin-right: auto;
    }

    .modal__warning {
      flex: 1 0 100%;
      margin: rem(4px) 0 0;
      color: $color__red-100;
      @include font-size(14px);
      line-height: 1;
      text-align: right;
    }
  }

  &__content {
    flex: 1 1 100%;
    overflow-y: scroll;

    &-wrapper {
      padding: rem(32px) rem(16px);
    }
  }

  dl {
    margin: 0;
  }

  &__subtitle {
    @extend %subtitle;
    color: $color__gray-460;
    text-transform: uppercase;

    &--members {
      padding-top: rem(32px);
      border-top: 1px solid $color__gray-420;
    }
  }

  &__value {
    margin: 0 0 rem(16px);
    @extend %body__text-l;
  }

  &__org-link {
    @extend %body__text-xs;

    .icon {
      margin-left: rem(4px);
      font-size: 0.8em;
    }
  }

  &__orig-lang {
    padding-top: rem(32px);
    @extend %body__text-l;
    color: $color__gray-300;
    border-top: 1px solid $color__gray-420;
  }

  &__mem-list {
    @extend %body__text-m;

    p {
      margin: rem(8px) 0 0;
    }

    ul {
      margin: 0 0 rem(20px);
      padding: 0;
      list-style: none;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    li {
      padding-left: 1rem;
      text-indent: -1rem;

      // stylelint-disable-next-line
      p {
        margin: 0;
      }
    }
  }
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}
