@use 'abstracts' as *;

:root {
  --scrollbar: 0;
  --optionsHeight: 0;
  --font-size--sm: 1;
  --font-size--md: 1.1;
  --font-size--lg: 1.2;
  --table-font-size: var(--font-size--sm);
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  padding: 0 rem($padding--mobile);
  overflow-x: hidden;
  font-family: $font__roboto;
  background-color: $color__gray-400;

  @include breakpoint('medium') {
    padding-right: rem($padding--desktop);
    padding-left: rem($padding--desktop);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: block;
  }

  &::before {
    background-image: url('../../assets/tech-code-bg.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    opacity: 0.1;
  }

  &::after {
    background-image: linear-gradient(
      to bottom,
      rgba(67, 87, 102, 0),
      rgba(42, 57, 68, 0.71) 35%,
      $color__gray-400 82%
    );
  }
}

.body--disabled {
  height: 100%;
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: $size__container-max-width;
  margin-right: auto;
  margin-left: auto;

  &--inner {
    width: 100%;
    max-width: $size__container-max-width--inner;
    margin-right: auto;
    margin-left: auto;
  }
}

// Offset padding on body on table section
.datatable {
  margin-right: rem(-$padding--mobile);
  margin-bottom: rem(48px);

  @include breakpoint('medium') {
    margin-right: rem(-$padding--desktop);
  }
}
