@use 'abstracts' as *;

.loader__container {
  position: relative;
  height: 100vh;
}

.loader {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    margin-top: 15px;
    color: $color__gray-200;
    font-family: $font__roboto;
  }

  &__spinner {
    width: 50px;
    height: 50px;
    border: 3px solid transparent;
    border-top-color: $color__gray-200;
    border-right-color: $color__gray-200;
    border-bottom-color: $color__gray-200;
    border-radius: 50%;
    animation: load 1s linear infinite;
  }
}

@keyframes load {
  25% {
    transform: rotate(100deg);
  }

  50% {
    transform: rotate(200deg);
  }

  75% {
    transform: rotate(300deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
