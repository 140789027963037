@use 'abstracts' as *;

.back-to-top {
  position: fixed;
  right: rem(20px);
  bottom: rem(20px);
  z-index: 10;
  display: flex;
  padding: rem(7px);
  color: $color__white;
  background-color: $color__blue-500;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba($color__black, 0.34);
  @include font-size(26px);
  transition: background-color 0.3s ease-in-out;
  
  &:hover {
    background-color: $color__blue-400;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $color__blue-300;
  }
  
  @include breakpoint('large') {
    right: rem(40px);
    bottom: rem(40px);
  }
}
