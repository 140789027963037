/*=================================
=            Variables            =
=================================*/

/*----------  Colors  ----------*/

$color__white: #fff;
$color__white-125: rgba(255, 255, 255, 0.25);
$color__white-130: rgba(255, 255, 255, 0.3);
$color__white-160: rgba(255, 255, 255, 0.6);
$color__white-175: rgba(255, 255, 255, 0.75);
$color__white-190: rgba(255, 255, 255, 0.9);

$color__highlight-row: #f1f5f7;
$color__highlight-cell: #e6ebef;

$color__blue-100: #b4d3e9;
$color__blue-200: #66a5d2;
$color__blue-300: #3385bd;
$color__blue-400: #0065a4;
$color__blue-500: #00528a;
$color__blue-600: #003f6c;
$color__blue-700: #89b9dc;

$color__red-100: #d04023;

$color__gray-100: #d2d8db;
$color__gray-200: #c0c4ca;
$color__gray-300: #242c32;
$color__gray-400: #202c36;
$color__gray-420: rgba(32, 44, 54, 0.2);
$color__gray-430: rgba(32, 44, 54, 0.3);
$color__gray-460: rgba(32, 44, 54, 0.6);
$color__gray-487: rgba(32, 44, 54, 0.87);
$color__gray-500: #131f2a;
$color__gray-600: #08121a;
$color__gray-700: #979797;

$color__black: #000;
/*----------  Typography  ----------*/

$font__roboto: Roboto, sans-serif;
$font__expo: expo-serif-pro, serif;

/*----------  Structure  ----------*/

$breakpoints: (
  'small': 0,
  'medium': 37.5em,
  'large': 64em
);

$size__container-max-width: 1200px;
$size__container-max-width--inner: 980px;

$padding--mobile: 20px;
$padding--desktop: 40px;
