@use 'abstracts' as *;

.methodology {
  position: relative;
  display: grid;
  grid-column-gap: rem(72px);
  grid-row-gap: rem(16px);
  padding: rem(48px) 0 rem(72px);

  @include breakpoint('medium') {
    grid-template-columns: 2fr 1fr;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc((100vw - 100%) / -2);
    display: block;
    @include vw100;
    height: 1px;
    background-color: $color__gray-700;
    opacity: 0.37;
  }

  &__title {
    grid-column: 1 / -1;
    margin-top: 0;
    margin-bottom: 0;
    color: $color__white;
    font-family: $font__expo;
    @include font-size(34px);
    line-height: 1;
  }

  &__desc {
    margin-bottom: 0;
    color: $color__white-190;
    font-weight: 300;
    @extend %body__text-l;
  }

  &__program-desc {
    margin-bottom: 0;
    color: $color__white-190;
    font-weight: 300;
    @extend %body__text-m;

    @include breakpoint('medium') {
      padding-left: rem(24px);
    }

    a {
      color: $color__blue-200;
      font-weight: normal;
      transition: all 0.5s ease;

      &:hover {
        color: $color__blue-700;
        text-decoration: none;
      }
    }
  }
}
