@use 'abstracts' as *;

.table-search {
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: rem(10px);

  @include breakpoint('large') {
    margin-bottom: 0;
  }

  &__input-field,
  &__dropdown select,
  .icon-search {
    height: rem(37px);
    color: $color__white-190;
  }

  &__input {
    flex: 1;
    background-color: $color__gray-500;

    @include breakpoint('large') {
      max-width: 280px;
    }

    // Text fade out at end of field
    &::before {
      background-image: linear-gradient(
        to right,
        rgba(19, 31, 42, 0) 0%,
        $color__gray-500 100%
      );
    }

    &-field,
    .icon {
      @extend %input;
    }

    &-field {
      width: 100%;
      min-width: 180px;
      background-color: $color__gray-500;

      @include breakpoint('large') {
        min-width: 280px;
      }

      &:focus {
        box-shadow: inset 0 0 0 2px $color__blue-300,
          inset 0 1px 2px 0 rgba(102, 113, 123, 0.21),
          inset 0 0 0 1px rgba(102, 113, 123, 0.25);
      }

      &::placeholder {
        color: $color__white-130;
      }
    }
  }

  .icon-close-circle {
    color: $color__white-160;

    &:hover {
      color: $color__white-190;
    }
  }

  &__dropdown {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    background-color: $color__gray-600;
    border-radius: 2px;

    @include breakpoint('medium') {
      margin-right: rem(25px);
    }

    // Text fade out at end of field
    &::before {
      content: '';
      position: absolute;
      right: rem(30px);
      width: rem(20px);
      height: 80%;
      background-image: linear-gradient(
        to right,
        rgba(8, 18, 26, 0) 0%,
        $color__gray-600 100%
      );
      pointer-events: none;
    }

    select {
      max-width: rem(137px);
      padding: rem(8px) rem(30px) rem(8px) rem(12px);
      background-color: $color__gray-600;
      border: 0;
      border-radius: 2px;
      appearance: none;
      @extend %input;

      &:focus {
        box-shadow: inset 0 0 0 2px $color__blue-300;
      }

      @include breakpoint('large') {
        max-width: rem(190px);
      }
    }

    .icon {
      position: absolute;
      right: rem(12px);
      z-index: 99;
      color: $color__white-160 !important;
      pointer-events: none;
    }
  }
}
