.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  transition: color 0.3s ease-in-out;
  fill: currentColor;
  stroke-width: 0;
  stroke: currentColor;

  a &,
  .btn--icon & {
    transition: none; // Transition is applied at the a level instead of the icon
  }
}

.email {
  width: 1.349609375em;
}
