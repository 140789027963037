@use 'abstracts' as *;

.modal-search {
  margin: 0;
  padding: rem(16px);
  background-color: $color__highlight-row;
  border: 0;

  &__wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  &__input {
    flex: 1;
    margin: 0 rem(8px) 0 0;

    .icon-search {
      color: $color__black;
    }

    .icon-close-circle {
      color: $color__gray-430;

      &:hover {
        color: $color__gray-460;
      }
    }

    &::before {
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, $color__white 100%);
    }

    &-field {
      width: 100%;
      color: $color__gray-300;
      @extend %body__text-m;
      background: rgba($color__white, 0.85);
      box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.21), inset 0 0 0 1px rgba(102, 113, 123, 0.25);

      &:focus {
        box-shadow: inset 0 0 0 1px $color__blue-400, inset 0 1px 2px 0 rgba(102, 113, 123, 0.21), inset 0 0 0 1px rgba(102, 113, 123, 0.25);
      }
  
      &::placeholder {
        color: $color__gray-430;
      }
    }
  }

  &__results {
    flex: 1 0 100%;
    margin: rem(8px) 0 0;
    color: $color__gray-460;
    @extend %body__text-s;
  }
}
