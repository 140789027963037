@use 'abstracts' as *;

.filter__button {
  padding: rem(8.5px) rem(16px);
  
  .icon {
    @include font-size(12px);
  }

  &:focus:hover {
    box-shadow: 0 0 0 2px #85b7db;
  }
}
