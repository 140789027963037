@use 'variables' as *;
@use 'mixins' as *;

/*====================================
=            Placeholders            =
====================================*/

// will create media query to resize font for mobile 22px
%modal__text {
  color: $color__gray-100;
  font-weight: 500;
  line-height: 1;
  @include font-size(24px);
}

%table__header {
  font-weight: 500;
  line-height: 1.2;
  @include font-size(16px);
  @include breakpoint('medium') {
    @include font-size(18px);
  }
}

%body__text-l {
  line-height: 1.53;
  @include font-size(18px);
}

%body__text-m {
  line-height: 1.41;
  @include font-size(16px);
}

%body__text-s {
  line-height: 1.41;
  @include font-size(14px);
}

%body__text-xs {
  line-height: 1.2;
  @include font-size(13px);
}

// Potentially unused
%body__text-list {
  line-height: 1.33;
  @include font-size(18px);
}

%input {
  line-height: 1.25;
  @include font-size(16px);
}

%subtitle {
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.5px;
  @include font-size(14px);
}

%button {
  font-weight: 500;
  line-height: 1.37;
  letter-spacing: 0.2px;
  text-align: center;
  @include font-size(14px);
}
